import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import EmailMessage from 'scenes/Administration/Emails/components/EmailMessage';
import '../../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Accordion from '../../../../../../../components/Accordion';
import InputField from '../../../../../../../components/InputField';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import Tooltip from '../../../../../../../components/Tooltip';
import utilities from '../../../../../../../utilities';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import { RCUContext } from '../../../../../../../utilities/services/contexts';
import IconManager from '../../../../../../../utilities/services/IconManager';
import './styles.scss';

const GeneralSettings = ({ domains, errMessage }) => {
  const baseClass = 'ickyc-general-settings';
  const setSuffix = setClassSuffix(baseClass);
  const {
    values: {
      generalSettings: { domain },
    },
  } = useFormState();

  const { batch, change } = useForm();

  const {
    initialConfiguration: { emailMessage: emailMessageInitial, emailSubject, linkText, supportEmail, validateLink },
  } = useContext(RCUContext);

  const goTolInk = () => {
    if (domain && domain !== '') window.open(domain.includes('https://') ? domain : `https://${domain}`, '_blank');
  };
  const copyText = () => {
    utilities.copyToClipboard(domain);
  };

  useEffect(() => {
    if (emailSubject) {
      batch(() => {
        change('generalSettings.emailSubject', emailSubject);
      });
    }

    if (emailMessageInitial) {
      batch(() => {
        change('generalSettings.emailMessage', emailMessageInitial);
      });
    }

    if (linkText) {
      batch(() => {
        change('generalSettings.linkText', linkText);
      });
    }
    if (supportEmail) {
      batch(() => {
        change('generalSettings.supportEmail', supportEmail);
      });
    }
  }, [emailMessageInitial, emailSubject, linkText, supportEmail, batch, change]);

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.GENERAL_SETTINGS}
      title={
        <>
          <h3>General Settings</h3>
          <span className="ickyc-spacer" />
          {errMessage && <div className={setSuffix('__error')}>{errMessage}</div>}
        </>
      }
    >
      <div className={baseClass}>
        <div>
          <span className={setSuffix('__label')}>Portal</span>
          <div className={`${setSuffix('__domain-box')} ${setSuffix('__input-box')}`}>
            <Field
              name="generalSettings.domain"
              component={SelectField}
              options={domains}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
            />
            <Tooltip
              trigger={
                <div className={setSuffix('__icon')} onClick={copyText}>
                  {IconManager.get(IconManager.names.COPY)}
                </div>
              }
              content={<div>Copy</div>}
            />

            <Tooltip
              trigger={
                <Tooltip
                  trigger={
                    <div className={setSuffix('__icon')} onClick={goTolInk}>
                      {IconManager.get(IconManager.names.LINK)}
                    </div>
                  }
                  content={<div>Open in New Tab</div>}
                />
              }
              content={<div>Copy</div>}
            />
          </div>
        </div>

        <div>
          <span className={setSuffix('__label')}>Client Email</span>
          <Field
            className={setSuffix('__input-box')}
            name="generalSettings.clientEmail"
            placeholder="user@domain.com"
            preview
            disabled
            component={InputField}
          />
        </div>
        <div>
          <span className={setSuffix('__label')}>Email Subject</span>
          <Field
            className={setSuffix('__input-box')}
            name="generalSettings.emailSubject"
            placeholder="Subject"
            component={InputField}
          />
        </div>
        <EmailMessage
          className={setSuffix('__input-box')}
          messageName="generalSettings.emailMessage"
          linkTextName="generalSettings.linkText"
          supportEmailName="generalSettings.supportEmail"
          isLinkRequired={validateLink}
          label="Email Message"
        />
      </div>
    </Accordion>
  );
};
GeneralSettings.propTypes = {
  domains: PropTypes.array,
  errMessage: PropTypes.string,
};
GeneralSettings.defaultProps = {
  domains: [],
  errMessage: null,
};
export default GeneralSettings;
